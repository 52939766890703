import React from 'react';

import { Stack, styled, Theme } from '@mui/material';

import { StyledMUICheckbox, StyledText } from '@/components/lib';
import { TextProps } from '@/components/lib/components/Text/Text';

export const StyledContainer = styled(Stack)({
  width: '100%',
  minWidth: '700px'
});

export const StyledRowContainer = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '10px',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px 22px 5px 10px'
}));

interface StyledRowBaseContainerProps {
  row?: string;
  column_count?: number;
}

export const StyledRowBaseContainer = styled(Stack)<StyledRowBaseContainerProps>(({ row, column_count }) => ({
  flexDirection: 'row',
  width: row
    ? `calc(100% - (${(column_count || 1) * 110}px + 110px + 145px))`
    : `calc(100% - (${column_count === 3 ? 0 : 52}px + ${(column_count || 1) * 110}px + 110px + 145px))`,
  alignItems: 'center'
}));

export const StyledColumnContainer = styled(Stack)<StyledRowBaseContainerProps>(({ row, column_count, theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: `calc(${row || column_count === 3 ? 0 : 52}px + ${(column_count || 1) * 110}px + 110px + 145px)`,
  gap: 0,
  padding: 0,

  '.MuiSvgIcon-root': { color: theme.palette.grey[400] }
}));

export const StyledCheckBox = styled(StyledMUICheckbox)(({ theme, checked, disabled, indeterminate }) => ({
  padding: 0,
  margin: '0 8px 0 -3px',

  '& svg': {
    fill: (checked || indeterminate) && !disabled ? theme.palette.primary.main : theme.palette.grey[300]
  }
}));

interface StyledTableBodyProps {
  isTopicsAvailable: boolean;
}

export const StyledTableBody = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isTopicsAvailable'
})<StyledTableBodyProps>(({ isTopicsAvailable, theme }) => ({
  gap: '8px',
  overflow: 'auto',
  height: `calc(100vh - ${isTopicsAvailable ? 290 : 220}px)`,

  '&::-webkit-scrollbar': {
    width: '4px',
    marginLeft: '8px'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px'
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.grey[400]
  },

  '@media (max-width: 1115px)': {
    height: `calc(100vh - ${isTopicsAvailable ? 350 : 280}px)`
  },

  '@media (max-width: 770px)': {
    height: `calc(100vh - ${isTopicsAvailable ? 400 : 330}px)`
  }
}));

interface StyledListItemTextProps {
  selected?: boolean;
}

export const StyledListItemText = styled((props: TextProps) => <StyledText type="bodyBold" {...props} />, {
  shouldForwardProp: prop => prop !== 'selected'
})<StyledListItemTextProps>(({ theme, selected }) => ({
  whiteSpace: 'nowrap',
  marginRight: 'auto',
  color: selected ? theme.palette.primary.main : theme.palette.grey[600]
}));

export const sxSuccessStyles = (theme: Theme) => ({ fill: theme.palette.success.main });
export const sxLowGreyStyles = (theme: Theme) => ({ color: theme.palette.grey[300] });
export const sxErrorStyles = (theme: Theme) => ({ fill: theme.palette.error.main });
export const sxGreyStyles = (theme: Theme) => ({ fill: theme.palette.grey[500] });
